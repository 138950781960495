// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-blogs-tsx": () => import("./../../../src/pages/blogs.tsx" /* webpackChunkName: "component---src-pages-blogs-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-members-tsx": () => import("./../../../src/pages/members.tsx" /* webpackChunkName: "component---src-pages-members-tsx" */),
  "component---src-pages-sohosai-2022-free-tsx": () => import("./../../../src/pages/sohosai2022/free.tsx" /* webpackChunkName: "component---src-pages-sohosai-2022-free-tsx" */),
  "component---src-pages-sohosai-2022-theme-tsx": () => import("./../../../src/pages/sohosai2022/theme.tsx" /* webpackChunkName: "component---src-pages-sohosai-2022-theme-tsx" */),
  "component---src-pages-sohosai-2022-tsx": () => import("./../../../src/pages/sohosai2022.tsx" /* webpackChunkName: "component---src-pages-sohosai-2022-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-gallery-photo-tsx": () => import("./../../../src/templates/gallery-photo.tsx" /* webpackChunkName: "component---src-templates-gallery-photo-tsx" */),
  "component---src-templates-static-page-tsx": () => import("./../../../src/templates/static-page.tsx" /* webpackChunkName: "component---src-templates-static-page-tsx" */)
}

